import React from "react";

function Content() {
  return (
    <div className="content">
      <div className="background">
        <h1>PROJET TACHJIR</h1>
        <h2>Pour un déploiement à l'échelle de l'agroforesterie au Maroc</h2>
      </div>
      <div className="text">
        <p>
          En réponse à la crise climatique et aux enjeux de souveraineté alimentaire, l'INRA et ses partenaires ont organisé sous le patronage du Ministère de l'Agriculture, de la Pêche Maritime, du Développement Rural et des Eaux et Forêts, des ateliers participatifs visant à co-construire une feuille de route ambitieuse pour le déploiement de systèmes agroforestiers à l'échelle nationale.
        </p>
        <p>Ces ateliers qui se sont tenus à l'échelle nationale et régionale s'inscrivent dans une démarche:</p>
        <ol>
        <li>- De co-construction inclusive avec l'ensemble des parties prenantes de l'écosystème agricole marocain.</li>
        <li>- Systémique capitalisant sur tous les leviers de l'agroécologie avec un focus fort sur l'optimisation du cycle de l'eau.</li>
        <li>- Fondée sur la science et capitalisant sur le savoir-faire local, l'expertise de terrain ainsi que l'innovation technologique (Data, IA, Digital).</li>
        </ol>
        <div className="quote">
          <p>
              En réunissant les principaux décideurs de la chaine de valeur agricole, ces échanges ont permis à chacun d'enrichir la feuille de route pour une agriculture marocaine résiliente, durable et souveraine.
          </p>
          <h2>DE LÀ EST NÉ LE PROJET TACHJIR</h2>
        </div>
        
      </div>
      <img src="./footer.png" alt="footer" />
    </div>
  );
}

export default Content;
